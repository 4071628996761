import LayoutA1 from "../../components/layout-a1"
import React from "react"
import './raceInfo.css'


export default class RaceInfo extends React.Component {
  render() {
    console.log("this.props:",this.props)
    const infotype = this.props.pageContext.props.infotype
    console.log("infotype",infotype)
    const topTitle=infotype==='rules'?'竞赛规程':'赛事路线'
    const raceName='2019簇格马拉松竞赛规程'
    const contents=this.props.pageContext.props.contents

    const contentlist=contents.map((content,index)=>
      <div key={index} className="content">
        <p>{content.title}</p>
        {content.content.map((item,index)=>
          <div key={index}>
            {item.type==='text'&&<p key={index}>{item.text}</p>}
            {item.type==='img'&&<img alt="hello" key={index}  src={item.src}/>}
          </div>

        )}
      </div>)
    return(
      <LayoutA1 location={this.props.location} >
        <div className="rules">
          <h4>{topTitle}</h4>
          <p className="race-name">{raceName}</p>
          <div>{contentlist}</div>
        </div>
      </LayoutA1>
    )
  }
}
